import {Auth} from "aws-amplify";
import {
  logoutUser,
  clearPublicUser,
  clearAppointmentBookingDetails,
  clearClassBookingDetails,
} from "../redux/actions"; // adjust the paths
import {store} from "../redux/configureStore"; // the store where your Redux is set up

export const handleLogout = async () => {
  try {
    await Auth.signOut();
    // Dispatch actions to clear the Redux store
    store.dispatch(logoutUser());
    store.dispatch(clearPublicUser());
    store.dispatch(clearAppointmentBookingDetails());
    store.dispatch(clearClassBookingDetails());

    // Clear local storage and redirect to login
    localStorage.clear();
    window.location.replace("/login");
  } catch (error) {
    console.error("Error during logout:", error);
  }
};
