import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import Moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {CalendarFms} from '..';
import {authCompanyData} from '../../redux/actions';
import {AxiosHelperPortal, BASE_URL_WEB, getInitials, IMAGE_URL, showToast} from '../../utility';

const DashboardEventsDetailsModal = ({show, onHide, details}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const isSessionTimeInNext24Hours = () => {
    const sessionTime = Moment(details?.start_branch_time);
    const currentTime = Moment();
    const diff = sessionTime.diff(currentTime, 'hours');
    return diff < 24;
  };

  const onCancel = async () => {
    const payload = {
      cancelAllAppointments: null,
      cancelType: 'session_credit',
      cancelledReason: '',
      partialRefundAmount: null,
      refundType: null,
      isGroupClass: details.typename === 'group_class_members' ? 'yes' : 'no',
      clientPortal: 'yes',
      groupClassId: details.typename === 'group_class_members' ? details?.groupClassId : null,
      orderId: details?.orderId,
      uuid: details?.uuid,
      paymentMethod: null,
      isMember: true,
      from: "client_portal",
      isFromClient:true
    };
    const url = `${BASE_URL_WEB}appointment-cancel?`;
    console.log('url', url, payload);
    // eslint-disable-next-line no-unused-vars
    try {
      const response = await AxiosHelperPortal(url, 'post', payload);
      console.log('response', response);
      showToast.success('Your session is cancelled successfully.');
      setShowCancelDialog(false);
      onHide();
      setTimeout(() => {
        dispatch(authCompanyData());
      }, 1000);
    } catch (error) {
      console.log('err', error);
      showToast.error(error.response?.data?.message);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content appt-booking-modal">
          <div className="modal-header">
            <h5 className="modal-title">{`${
              details?.typename === 'group_class_members' ? 'Class Details' : 'Appointment Details'
            }`}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            ></button>
          </div>
          <div className="modal-body">
            <div className="_clientProDiv">
              <span className="_proPic">
                {details?.imageUrl ? (
                  <img src={IMAGE_URL + details?.imageUrl} alt="" />
                ) : (
                  <span className="avName">
                    <span className="proName">
                      {details?.trainerName !== null && details?.trainerName !== undefined
                        ? getInitials(
                            details?.trainerName?.split(' ')[0],
                            details?.trainerName?.split(' ')[1]
                          )
                        : ''}
                    </span>
                  </span>
                )}
              </span>
              <div className="_clCont">
                <p className="_name text-capitalize mb-2">{details?.serviceName}</p>
                <p className="_name _title-3 text-capitalize">
                  <span className="icon-holder-txt">
                    {details?.virtualSession && <i className="fas fa-video"></i>}
                    {details?.branchName}
                  </span>
                </p>
                {/* {details?.virtualSession && (
                  <i className="ml-2 fas fa-video"></i>
                )} */}

                <p className="coach_name text-capitalize">
                  Coach: <span className="trainer">{details?.trainerName}</span>
                </p>

                <div>
                  <p className="coach_name text-capitalize mb-3">
                    Booked Family Member:{' '}
                    <span className="trainer">
                      <CalendarFms data={details} isCalendar={false} />
                    </span>
                  </p>
                </div>

                <div className="_finalDate">
                  <p>
                    <i className="bx bx-calendar-event"></i>
                    {Moment(details?.start_branch_time).format('dddd, MMMM Do YYYY')}
                  </p>
                  <br />{' '}
                  <p>
                    <i className="bx bx-time-five"></i>
                    {Moment(details?.start_branch_time).format('hh:mm A')}
                  </p>
                </div>
              </div>
            </div>

            <div className="row d-flex col-12 justify-content-center">
              {details?.typename !== 'group_class_members' && (
                <button
                  className="btn btn-primary col-4 mr-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => history.push('/reschedule', {details})}
                >
                  Reschedule
                </button>
              )}
              <button
                className="btn btn-primary col-4 ml-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  if (details.typename === 'appointment' && isSessionTimeInNext24Hours()) {
                    showToast.error(
                      'Appointment can not be cancelled within 24 hours of start time.'
                    );
                    return;
                  }
                  setShowCancelDialog(!showCancelDialog);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {showCancelDialog && (
        <SweetAlert
          showCancel
          confirmBtnText="Confirm"
          title="Are you sure you want to cancel?"
          onConfirm={onCancel}
          onCancel={() => setShowCancelDialog(!showCancelDialog)}
          focusCancelBtn
          cancelBtnText="Back"
        />
      )}
    </>
  );
};

export default DashboardEventsDetailsModal;
