import moment from "moment";
import { BOOKING_TYPES } from "../../common/config";
import { AxiosHelperPortal } from "../../utility/helperFunctions";
import { BASE_URL_WEB } from "../../utility/constants";

export const getSessionFm = ({ selectedFmUuidSession, compFm, member }) => {
  const isParentSelected = selectedFmUuidSession === "_parent";
  if (!isParentSelected) {
    const selectedFM = compFm?.find((fm) => fm?.uuid === selectedFmUuidSession);
    return {
      memberFirstName: selectedFM?.firstName,
      memberLastName: selectedFM?.lastName,
      typename: BOOKING_TYPES.APPOINTMENT,
    };
  } else {
    return {
      memberFirstName: member?.firstName,
      memberLastName: member?.lastName,
      typename: BOOKING_TYPES.APPOINTMENT,
    };
  }
};

export const getSessionPayload = ({
  selectedPack,
  bookingDetails,
  companyData,
  coupon,
}) => {
  const selectedDate = bookingDetails?.trainingTime.date;
  const selectedTime = bookingDetails?.trainingTime.time;
  const startMoment = moment(selectedDate + " " + selectedTime + ":00");
  const start = startMoment.format("YYYY-MM-DDTHH:mm");
  const end = startMoment
    .add(bookingDetails.service.duration, "minutes")
    .format("YYYY-MM-DDTHH:mm");

  let payload = {
    start: start,
    end: end,
    quantity: selectedPack?.quantity,
    price: selectedPack?.price,
    packageLabel: selectedPack?.label,
    trainerId: bookingDetails.trainer.branchTrainersTrainerId,
    status: "active",
    from: "clp",
    memberId: companyData.member.id,
    companyId: bookingDetails.service.companyId,
    category: "pt",
    branchId: bookingDetails.trainer.branchTrainersBranchId,
    branchTrainerTableId: bookingDetails.trainer.id,
    trainerName: bookingDetails.trainer.trainerName,
    memberName: companyData.member.name,
    branchName: companyData.branch.name,
    companyName: companyData.name,
    timezone: bookingDetails.trainer.timezone,
    imageUrl: null,
    serviceId: bookingDetails.service.uuid,
    memberFirstName: companyData.member.firstName,
    memberLastName: companyData.member.lastName,
    serviceName: bookingDetails.service.serviceName,
    duration: bookingDetails.service.duration,
    categoryId: bookingDetails.service.categoryId,
    extraTime: 0,
    notes: "",
  };
  const isParentSelected = bookingDetails?.selectedFmUuidSession === "_parent";
  if (!isParentSelected) {
    const selectedFM = companyData?.familyMembers?.find(
      (fm) => fm?.uuid === bookingDetails?.selectedFmUuidSession
    );
    payload.fmID = selectedFM?.uuid;
    payload.memberFirstName = selectedFM?.firstName;
    payload.memberLastName = selectedFM?.lastName;
  }
  if (coupon != null && selectedPack?.price) {
    payload.coupon_code = coupon.coupon;
    payload.price =
      parseFloat(selectedPack?.price) -
      coupon.percent * 0.01 * parseFloat(selectedPack?.price);
  }
  return payload;
};

const getFilteredClass = ({ bookingType, bookingDetails, group_class }) => {
  const filteredClass = group_class
    ?.filter(
      (item) =>
        item.uuid ===
        (bookingType === BOOKING_TYPES.APPOINTMENT
          ? bookingDetails.service?.serviceId
          : bookingDetails?.classDetails?.serviceId)
    )
    .map((item) => ({
      ...item,
      name: `${item?.serviceName} - Single class pack`,
      subDescription: `Credits from this pack can only be used on ${item?.serviceName}`,
    }));

  return filteredClass;
};

export const getClassPayload = ({
  selectedPack,
  bookingType,
  bookingDetails,
  authDetails,
  coupon,
  isCamp,
  multiClassId,
  packQuantityFamilyMember,
  group_class,
}) => {
  const filteredClass = getFilteredClass({
    bookingType,
    bookingDetails,
    group_class,
  });
  let payload = {
    ...bookingDetails?.classDetails,
    payment_method: "cash",
    quantity: selectedPack?.quantity ? selectedPack?.quantity : 1,
    price: selectedPack?.price,
    packageLabel: selectedPack?.label,
    status: "active",
    memberId: authDetails.companyData.member.id,
    companyId: authDetails.companyData.id,
    branchId: authDetails.companyData.branch.id,
    memberName:
      authDetails.companyData.member.firstName +
      " " +
      authDetails.companyData.member.lastName,
    branchName: authDetails.companyData.branch.name,
    companyName: authDetails.companyData.name,
    timezone: authDetails.companyData.branch.timezone,
    serviceId: filteredClass[0]?.uuid,
    memberFirstName: authDetails.companyData.member.firstName,
    memberLastName: authDetails.companyData.member.lastName,
    serviceName: bookingDetails?.classDetails?.serviceName,
    duration: bookingDetails?.classDetails?.duration,
    categoryId: filteredClass[0]?.categoryId,
    extraTime:
      bookingDetails?.classDetails?.extraTimeEnable === true
        ? bookingDetails?.classDetails?.extraTime
        : 0,
  };

  if (coupon != null && selectedPack?.price) {
    payload.coupon_code = coupon.coupon;
    payload.price =
      parseFloat(selectedPack?.price) -
      coupon.percent * 0.01 * parseFloat(selectedPack?.price);
  }
  if (bookingDetails?.classDetails?.haveKids) {
    payload.familyMembers = bookingDetails?.selectedFamilyMembers;
  }

  if (isCamp) {
    payload.bookType = selectedPack?.campBookType;
    payload.payMembershipFee = selectedPack?.isOneTimeMembershipFeeEnabled;
    if (
      bookingDetails?.selectedFamilyMembers?.length +
        bookingDetails?.classDetails?.familyMembers?.length >
        1 &&
      selectedPack?.extraPersonPrice
    ) {
      payload.price = selectedPack?.extraPersonPrice;
    }
  } else {
    if (bookingDetails?.classDetails?.haveKids) {

      if (packQuantityFamilyMember > 1) {
        payload.packQuantityFamilyMember = packQuantityFamilyMember;
      }
    }
    payload.multiClassPackId = multiClassId;
  }

  return payload;
};

export const getVerifyCoupon = async (couponCode,serviceId,bookingType) => {
  const url = `${BASE_URL_WEB}verify-coupon?coupon=${couponCode}&serviceId=${serviceId}&bookingType=${bookingType}`;
  //const url = `${BASE_URL_WEB}verify-coupon?coupon=${couponCode,serviceId}`;
  const response = await AxiosHelperPortal(url, "get");
  if (response?.data.valid) {
    return response?.data?.coupon;
  } else {
    return null;
  }
};

export const getRemainingForSessions = async ({
  authDetails,
  bookingDetails,
}) => {
  const memberId = authDetails.companyData?.member?.id;
  const serviceId = bookingDetails.service?.uuid;
  const companyId = bookingDetails.service?.companyId;
  const categoryId = bookingDetails.service?.categoryId;
  const url = `${BASE_URL_WEB}client-confirm-remaining?memberId=${memberId}&serviceId=${serviceId}&companyId=${companyId}&categoryId=${categoryId}`;
  const response = await AxiosHelperPortal(url, "get");
  return {
    count: response.data.ptRemaining,
    showPackage: response.data.ptRemaining < 1 && !response.data.isUnlimited, // sets show package true or false
    membership_pack: response.data.membership_pack,
    isQuantityUnlimited: response.data.unlimited,
  };
};

export const getRemainingForClass = async ({ classDetails }) => {
  const url = `${BASE_URL_WEB}client-class-member-service?serviceId=${classDetails?.serviceId}&groupClassId=${classDetails?.uuid}`;
  console.log("member-branch-trainer url", url);
  try {
    const response = await AxiosHelperPortal(url, "get");
    console.log("getRemainingForClass", response);
    return {
      count: response?.data?.length === 0 ? 0 : response?.data.ptRemaining,
      showPackage: true,
      membership_pack: response?.data?.membership_pack,
      isQuantityUnlimited: response?.data?.unlimited,
    };
  } catch (error) {
    console.log(error, error.response);
  }
};

export const campPacksList = ({ bookingDetails }) => {
  const list = [
    {
      label: "Book Single Event Day",
      price: parseFloat(bookingDetails?.classDetails?.single_day_price),
      campBookType: "single",
      isOneTimeMembershipFeeEnabled: false, // user interaction
      oneTimeMembershipFee: bookingDetails?.classDetails?.one_time_fee,
    },
    {
      label: "Book All Event Days",
      price: parseFloat(bookingDetails?.classDetails?.all_day_price),
      campBookType: "all",
      isOneTimeMembershipFeeEnabled: false, // user interaction
      oneTimeMembershipFee: bookingDetails?.classDetails?.one_time_fee,
    },
  ];
  return list;
};

export const campPackDefault = ({ bookingDetails }) => {
  if (!bookingDetails?.classDetails?.repeat_id) {
    return { ...campPacksList({ bookingDetails })[0], isSelected: true };
  } else if (
    bookingDetails?.classDetails?.repeat_id &&
    !bookingDetails?.classDetails?.allow_book_individually
  ) {
    return { ...campPacksList({ bookingDetails })[1], isSelected: true };
  }
};

export const validateCampAnsweredQuestions = ({
  haveKids,
  selectedFamilyMembers,
  campQuestions,
}) => {
  let questionErr = "";
  if (
    haveKids &&
    selectedFamilyMembers?.length !== 0 &&
    campQuestions?.length !== 0
  ) {
    const requiredQuestionIds = campQuestions
      ?.filter((e) => e.isRequired)
      .map((e) => e.uuid);
    for (let i = 0; i < selectedFamilyMembers.length; i++) {
      const currentChild = selectedFamilyMembers[i];
      for (let r = 0; r < requiredQuestionIds.length; r++) {
        if (
          !currentChild?.questions ||
          !currentChild?.questions[requiredQuestionIds[r]]?.a ||
          String(currentChild?.questions[requiredQuestionIds[r]]?.a)?.trim() ===
            ""
        ) {
          questionErr = `You need to answer mandatory questions for ${currentChild?.firstName} ${currentChild?.lastName}`;
          return questionErr;
        }
      }
    }
  }
};

export const calculateFMQuantity = ({
  bookingDetails,
  packageQuantity,
  remainingSession,
}) => {
  const selectedClass = bookingDetails?.classDetails;
  if (selectedClass?.haveKids !== true) {
    return 1;
  }
  let sessionQuantity = 1,
    sessionQuantityNeeded,
    quantity = 1;
  sessionQuantityNeeded =
    bookingDetails?.selectedFamilyMembers?.length > 0
      ? bookingDetails?.selectedFamilyMembers?.length
      : 1;

  if (remainingSession.count > 0) {
    sessionQuantityNeeded = sessionQuantityNeeded - remainingSession.count;
  }
  if (packageQuantity != null) {
    sessionQuantity = parseInt(packageQuantity ? packageQuantity : 1);
    while (sessionQuantityNeeded > sessionQuantity) {
      sessionQuantity =
        sessionQuantity + parseInt(packageQuantity ? packageQuantity : 1);
      quantity = quantity + 1;
    }
  }
  return quantity;
};

export const isCreditsAvailableSession = (remainingSession) => {
  console.log("remainingSession", remainingSession);
  let isAvailable = false;
  if (remainingSession?.membership_pack != null) {
    isAvailable = true;
  }
  if (remainingSession?.count > 0) {
    isAvailable = true;
  }
  return isAvailable;
};

export const calculateIsPackageNeeded = ({
  remainingSession,
  bookingDetails,
}) => {
  const selectedClass = bookingDetails?.classDetails;
  const membership_pack = remainingSession?.membership_pack;

  // TODO: Get membership pack from API and check if it is unlimited
  if (selectedClass?.haveKids !== true) {
    if (membership_pack != null) {
      if (membership_pack.isQuantityUnlimited === true) {
        return false;
      } else {
        if (membership_pack.remaining != null) {
          return membership_pack.remaining <= 0;
        }
      }
    }
    return remainingSession?.count <= 0;
  } else {
    let newRemaining = remainingSession?.count;
    if (membership_pack != null) {
      if (membership_pack.isQuantityUnlimited === true) {
        return false;
      } else {
        if (membership_pack.remaining != null) {
          //newRemaining = membership_pack.remaining;
        }
      }
    }
    let sessionQuantityNeeded =
      bookingDetails?.selectedFamilyMembers?.length > 0
        ? bookingDetails?.selectedFamilyMembers?.length
        : 1;
    return newRemaining < sessionQuantityNeeded;
  }
};

export const isSubmitDisabled = ({
  remainingSession,
  bookingType,
  bookingDetails,
  selectedPack,
  loading,
  isPrivateClass,
}) => {
  let disabled = false;
  //! if camp and is booked, can't book again
  //   if (
  //     bookingType === BOOKING_TYPES.CLASS &&
  //     bookingDetails?.classDetails?.isCamp &&
  //     bookingDetails?.classDetails?.isBooked
  //   ) {
  //     return true;
  //   }
  if (
    bookingType === BOOKING_TYPES.CLASS &&
    calculateIsPackageNeeded({ remainingSession, bookingDetails })
  ) {
    disabled = Boolean(
      typeof selectedPack === "undefined" ||
        selectedPack === null ||
        isPrivateClass
    );
  }
  if (
    bookingType === BOOKING_TYPES.APPOINTMENT &&
    remainingSession?.count === 0
  ) {
    disabled = Boolean(
      typeof selectedPack === "undefined" || selectedPack === null
    );
  }
  disabled = bookingDetails?.classDetails?.isClassFull || disabled;
  console.log("disabled", disabled);
  return loading.isSubmitBtn || disabled;
};

export const getMultiClassPackages = ({
  bookingType,
  bookingDetails,
  multi_class_pack,
  group_class,
}) => {
  if (bookingType === BOOKING_TYPES.APPOINTMENT) {
    return [{ ...bookingDetails.service }];
  }
  const multiClasses = [];
  multi_class_pack?.forEach((item) => {
    item.subDescription =
      "You can use credits from this pack across all classes";
    if (item.isAllClassPack) {
      multiClasses.push(item);
    } else if (
      item.selectedClasses.includes(bookingDetails?.classDetails?.serviceId)
    ) {
      multiClasses.push(item);
    }
  });
  const groupClass = getFilteredClass({
    bookingType,
    bookingDetails,
    group_class,
  });
  return [...multiClasses, ...groupClass];
};

// const onRefund = async () => {
//   const payload = {
//     cancelAllAppointments: true,
//     cancelType: "refund",
//     cancelledReason: "",
//     partialRefundAmount: null,
//     refundType: "full",
//     memberId: authDetails?.companyData?.member?.id,
//     isGroupClass: "yes",
//     clientPortal: "yes",
//     groupClassId: bookingDetails?.classDetails?.uuid,
//     orderId: bookingDetails?.classDetails?.orderId,
//     uuid: bookingDetails.classDetails?.bookingId,
//     fmId: bookingDetails?.bookingFamilyMembers?.bookedFm[0]?.uuid,
//   };
//   const url = `${BASE_URL_WEB}appointment-cancel?`;
//   const response = await AxiosHelperPortal(url, "post", payload);
//   console.log("response", response);
//   if (response.data.success) {
//     history.goBack();
//     toast.success(response.data.message);
//     return;
//   }
//   toast.error(response.data.message);
// };
