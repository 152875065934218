import {
  SIGNUP_CHANGE_SCREEN,
  SIGNUP_FM_ADD_FIELD_SINGLE,
  SIGNUP_FM_CHANGE_FIELDS_SINGLE,
  SIGNUP_FM_DELETE_FIELD_SINGLE,
  SIGNUP_ON_CHANGE_FIELDS_SINGLE,
  SIGNUP_RESET,
  SIGNUP_SET_FIELDS_MULTIPLE,
  SIGNUP_SET_INITIAL_DETAILS_FM,
  SIGNUP_SET_INITIAL_DETAILS_MEM_NAME,
  SIGNUP_SET_SUCCESS,
  SIGNUP_START_LOADING,
} from '../actionTypes';
import {SIGNUP_FIELDS, familyMemberFieldDefaultProps} from '../../pages/beforeLogin/SignUp/utils';

const INITIAL_STATE = {
  selectedScreen: '', // SIGNUP_SCREENS.TYPE_OF_SIGNUP,
  isAddFm: false,
  isSignUpSuccess: false,
  initialInputDetails: {
    ...familyMemberFieldDefaultProps,
  },
  loading: {
    signup: false,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGNUP_CHANGE_SCREEN: {
      console.log('mak 1', action.payload.isFm);
      return {
        ...state,
        selectedScreen: action.payload.screen,
        initialInputDetails: {
          ...state.initialInputDetails,
          [SIGNUP_FIELDS.FAMILY_MEM]:
            typeof action.payload.isFm !== 'undefined'
              ? action.payload.isFm
                ? [{firstName: ''}]
                : []
              : state.initialInputDetails?.familyMembers,
        },
      };
    }
    case SIGNUP_SET_INITIAL_DETAILS_MEM_NAME:
      return {
        ...state,
        initialInputDetails: {
          ...state.initialInputDetails,
          firstName: action.payload.payload,
        },
      };
    case SIGNUP_SET_INITIAL_DETAILS_FM: {
      const org = state.initialInputDetails.familyMembers;
      if (typeof action.payload.index !== 'undefined') {
        org[action.payload.index] = {firstName: action.payload.payload};
      } else {
        org.push({firstName: action.payload.payload});
      }
      return {
        ...state,
        initialInputDetails: {
          ...state.initialInputDetails,
          familyMembers: org,
        },
      };
    }
    case SIGNUP_ON_CHANGE_FIELDS_SINGLE: {
      return {
        ...state,
        initialInputDetails: {
          ...state.initialInputDetails,
          [action.payload.type]: action.payload.value,
        },
      };
    }
    case SIGNUP_FM_CHANGE_FIELDS_SINGLE: {
      const org = [...(state.initialInputDetails?.familyMembers || [])];
      org[action.payload.index][action.payload.type] = action.payload.value;

      return {
        ...state,
        initialInputDetails: {
          ...state.initialInputDetails,
          familyMembers: org,
        },
      };
    }
    case SIGNUP_FM_ADD_FIELD_SINGLE: {
      return {
        ...state,
        initialInputDetails: {
          ...state.initialInputDetails,
          familyMembers: state.initialInputDetails?.familyMembers?.concat({
            [SIGNUP_FIELDS.FIRST_NAME]: '',
          }),
        },
      };
    }
    case SIGNUP_FM_DELETE_FIELD_SINGLE: {
      const newFamilyMembersList = [
        ...state.initialInputDetails.familyMembers.slice(0, action.payload),
        ...state.initialInputDetails.familyMembers.slice(action.payload + 1),
      ];

      return {
        ...state,
        initialInputDetails: {
          ...state.initialInputDetails,
          familyMembers: newFamilyMembersList,
        },
      };
    }
    case SIGNUP_SET_FIELDS_MULTIPLE: {
      return {
        ...state,
        initialInputDetails: {
          ...state.initialInputDetails,
          ...action.payload,
        },
      };
    }
    case SIGNUP_START_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          signup: !state.loading.signup,
        },
      };
    }
    case SIGNUP_SET_SUCCESS: {
      return {
        ...INITIAL_STATE,
        isSignUpSuccess: true,
        email: action?.payload?.email,
      };
    }
    case SIGNUP_RESET: {
      return {
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
};

export default reducer;
