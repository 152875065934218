import React, { useState, useEffect } from "react";
import { changeClientPage, clearTrainer } from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { getInitials } from "../../utility/helperFunctions";
import { IMAGE_URL } from "../../utility/constants";
import { useHistory } from "react-router";
import { BookingSteps } from "../../components";

const BookingPage2 = () => {
  const history = useHistory();
  const [alertShow, setAlertShow] = useState({
    show: false,
    msg: "",
  });
  const [trainerDetails, setTrainerDetails] = useState();
  const [trainers, setTrainers] = useState([]);
  const dispatch = useDispatch();
  const authDetails = useSelector((state) => state.auth);
  const bookingDetails = useSelector((state) => state.booking);
  useEffect(() => {
    setTrainerImage();
    bookingDetails.trainer && setTrainerDetails(bookingDetails?.trainer);
  }, []);

  const setTrainerImage = () => {
    const temp = [];
    const selectedServiceTrainersIdArr =
      bookingDetails.service?.selectedTrainers?.map(
        (item) => item.branchTrainersTrainerId
      );
    const trainerValues = Object.values(authDetails.companyData.trainers.items);
    console.log(trainerValues);
    selectedServiceTrainersIdArr?.forEach((item) => {
      for (let trainer in trainerValues)
        if (
          trainerValues[trainer].branchTrainersTrainerId === item &&
          !temp.includes(trainerValues[trainer])
        ) {
          temp.push(trainerValues[trainer]);
        }
    });
    console.log(temp);
    setTrainers(temp);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container">
            <div className="container">
              <div className="_cardTop-hdr mb-4 text-center page-title">
                <h2 className="_text-dark">Select Coach</h2>
              </div>

              <BookingSteps step={2} />
              <div className="_sessionBookingPage mt-2">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="booking_page">
                      <div className="_bookingTable-outter _list-outter">
                        <ul className="_clientLists row ">
                          {trainers?.map((data) => (
                            <li
                              className="_clientList col-md-6"
                              key={data.branchTrainersTrainerId}
                            >
                              <label className="_b-label">
                                <input
                                  type="radio"
                                  name="service"
                                  value="1"
                                  checked={
                                    data.branchTrainersTrainerId ===
                                    trainerDetails?.branchTrainersTrainerId
                                  }
                                  onChange={() => setTrainerDetails(data)}
                                />
                                <div className="_clientList-div  _rightArr">
                                  <span className="_proPic">
                                    {data?.imageUrl ? (
                                      <img
                                        src={IMAGE_URL + data.imageUrl}
                                        alt=""
                                      />
                                    ) : (
                                      <span className="avName">
                                        <span className="proName">
                                          {data.firstName &&
                                            getInitials(
                                              data.firstName,
                                              data.lastName
                                            )}
                                        </span>
                                      </span>
                                    )}
                                  </span>
                                  <div className="_clCont">
                                    <p className="_name">{data.trainerName}</p>
                                    <p className="_info">coach</p>
                                  </div>
                                </div>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="_btnGrp text-center py-4">
            <button
              className="btn btn-outline-danger px-5"
              onClick={() => {
                dispatch(clearTrainer());
                history.push("class-booking?type=session");
              }}
            >
              Back
            </button>
            <button
              className="btn btn-primary px-5"
              onClick={() => {
                if (!trainerDetails) {
                  console.log("Store Details: ", trainerDetails);
                  setAlertShow({
                    show: true,
                    msg: "please select a trainer",
                  });
                } else {
                  dispatch(changeClientPage(trainerDetails));
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {alertShow.show && (
        <SweetAlert
          danger
          title="Sorry!!"
          onConfirm={() => setAlertShow({ ...alertShow, show: false })}
        >
          {alertShow.msg}
        </SweetAlert>
      )}
    </>
  );
};

export default BookingPage2;
