import moment from 'moment';
import React from 'react';

const AppDatePicker = ({maxYear, onChangeDate, val, maxDate}) => {
  const formattedValue = val ? moment(val).format('YYYY-MM-DD') : '';

  return (
    <input
      className="form-control"
      type="date"
      value={formattedValue} // Ensure value is in 'YYYY-MM-DD' format
      id="date-input"
      onChange={e => {
        const selectedDate = e.target.value;
        onChangeDate(selectedDate); // Pass as a string
      }}
      max={maxYear ? moment().subtract(maxYear, 'Y').format('YYYY-MM-DD') : maxDate}
    />
  );
};

export default AppDatePicker;
